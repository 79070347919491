import { css  } from '@emotion/core'
import { mq } from '../../cssInJs'

// export default {
//   top: css(mq({
//     //height: [44, null, 80],
//     height: [44, null, 56],
//     background: 'black',
//     position: 'fixed',
//     width: '100%',
//     zIndex: '999',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     transition: 'all 0.3s'
//   })),
//   // topScrolled: css(mq({
//   //   height: [44, null, 56],
//   // })),
//   logo: css(mq({
//   })),
//   logoImg: css(mq({
//     display: 'block',
//     height: [22, null, 36],
//   })),
//   logoScrolled: css(mq({

//   })),
// }

export default {
  top: css(
    mq({
      //height: [44, null, 80],
      height: [44, null, 56],
      background: "transparent",
      position: "fixed",
      width: "100%",
      zIndex: "999",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      transition: "all 0.3s",
    })
  ),
  topScrolled: css(
    mq({
      //height: [44, null, 56],
      background: 'white',
    })
  ),
  logo: css(mq({})),
  logoImg: css(
    mq({
      display: "block",
      height: [18, null, 36],
      //marginTop: ["3rem", null, "2.5rem"],
      transition: "all .2s",
    })
  ),
  logoScrolled: css(
    mq({
      img: css(
        mq({
          height: [18, null, 36],
          //marginTop: "0rem",
        })
      ),
    })
  ),
};