const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const colors = {
  colorBlue : '#004495',
  colorLightGray : '#555555',
  colorGray : '#3B3B3B',
  colorGray2 : '#727172'
}

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"nimbus-sans",serif',

  primaryColor: colors.colorBlue,
  fontColor: colors.colorGray,
  bgColor: '#ebebeb',

  ...colors,
  ...overloading
}

export default variables;