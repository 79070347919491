//import { css, keyframes  } from '@emotion/core'
//import { variables, mq } from 'cssInJs'

import { css } from '@emotion/core'
import { mq, variables } from '../../../cssInJs'

export default {
  pageTiles: css({
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    margin: '2rem -10px',
    overflow: 'hidden'
  }),

  slider: css({
    '.slick-prev': css(mq({
      left: ['0px !important', null, '-20px !important'],
    })),
    '.slick-next': css(mq({
      right: ['0px !important', null, '-20px !important']
    })),
    '.slick-next:before, .slick-prev:before': css({
      color : variables.colorBlue
    }),
    '.tile__details__container' : {
      height: '100%'
    }
  }),

  pageTile__tile: css(mq({
    flexGrow: '1',
    flexShrink: '1',
    padding: '10px',

    //for type1
    flexBasis: ['100%', null, '50%', '33.3%'],
    maxWidth: ['100%', null, '50%', '33.3%'],

    '&.type2.useSlider': css({
      flexBasis: '100%',
      maxWidth: '100%',
      height: '100%',

    }),

  })),

  tile__content: css({
    '&:hover': {
      textDecoration: 'none',
      color: variables.fontColor,
      '.pageTile__sharkCage__bg': css({
        //transform: 'scale(1.05)'
      }),
      '.pageTile__sharkCage__details': {
        backgroundColor: variables.colorBlue
      },
      // '.tile__details__container__border' : {
      //   backgroundColor: '#DD3430'
      // },
      // '.tile__details__container__border2' : {
      //   backgroundColor: 'white'
      // },
        
      '.info-wrapper': {
        '.info-title, .info-des': {
          color: 'white'
        },
        '.info__bottom__text' : {
          border : '2px solid white'
        }
      }
    },
    color: variables.fontColor,
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  }),

  tile__bg__wrapper: css({
    //height:'80%',
    overflow: 'hidden',
    flex: '0 0 auto',
    zIndex: 2,
    borderTopLeftRadius: '20px',
    borderTopRightRadius : '20px'
  }),

  tile__bg: css(mq({
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    transition: '.5s',
    height: '0px',
    //borderRadius: '100%',
    paddingBottom: '56.25%',
    zIndex: 2
  })),

  tile__details__container: {
    //marginTop: '-50%',
    position: 'relative',
    //filter: 'drop-shadow(-1px 1px 3px rgba(0, 0, 0, 0.1))',
    filter: 'drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.6))',
    zIndex: 2
    //height: '100%'
  },

  // tile__details__container__border: {
  //   position: 'absolute',
  //   backgroundColor: "#F9F8F8",
  //   width: 'calc(100% + 0.4px)',
  //   height: '20%',
  //   top: '-19.2%',
  //   clipPath: 'polygon(0 100%, 100% 20%, 100% 100%)',
  //   //zIndex: '-1'
  // },

  // tile__details__container__border2: {
  //   position: 'absolute',
  //   backgroundColor: "#F9F8F8",
  //   width: '100%',
  //   height: '20%',
  //   top: '-19.1%',
  //   clipPath: 'polygon(0 100%, 0 90%, 100% 20%, 100% 30%)'
  //   //zIndex: '-1'
  // },

  tile__details: css(mq({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 20px',
    backgroundColor: "#ffffff",
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px'
    //clipPath : 'polygon(0% 0%, 100% 0px, 100% calc(100% - 2rem), 50% 100%, 0px calc(100% - 2rem))'
  })),
  info__wrapper: css({
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    //alignItems: 'center',
    height: '100%',
    position: 'relative'
  }),
  info__title: css(mq({
    fontWeight: '700',
    color: variables.colorBlue,
    fontSize: ['25px',null,'30px'],
    lineHeight: 1,
    margin : 0,
    //fontFamily : variables.familyHeader
  })),
  info__des: css(mq({
    fontSize: ['1rem',null,'20px'],
    flexGrow: 1,
    color: variables.colorGray,
    lineHeight : '1.3',
    //textAlign: 'center',
    marginTop: '1rem'
  })),
  infoDesc: {
    display: '-webkit-box',
    WebkitLineClamp: '6',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  infoBottom: {
    //height: '1.5rem'
    marginTop: '1rem',
    '.info__bottom__text': mq({
      color : 'white',
      backgroundColor : variables.colorBlue,
      display:'inline-block',
      fontWeight : 700,
      padding : '0.2rem 2rem',
      borderRadius : '40px',
      fontSize : ['1rem',null,'20px'],
      border : '2px solid ' + variables.colorBlue
    })
  }
}